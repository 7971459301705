import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  connect() {
    console.log("Flatpickr controller connected!")

    // Start time
    const startTimeElement = document.getElementById('start-time');
    if (startTimeElement) {
      const startDefaultDate = startTimeElement.value ? startTimeElement.value : new Date();

      flatpickr(startTimeElement, {
        enableTime: true,
        defaultDate: startDefaultDate,
        dateFormat: "Y-m-dTH:i:S",
        altInput: true,
        altFormat: "d/m/Y h:i K"
      })
    }

    // End time
    const endTimeElement = document.getElementById('end-time');
    if (endTimeElement) {
      const endDefaultDate = endTimeElement.value ? endTimeElement.value : new Date();

      flatpickr(endTimeElement, {
        enableTime: true,
        defaultDate: endDefaultDate,
        dateFormat: "Y-m-dTH:i:S",
        altInput: true,
        altFormat: "d/m/Y h:i K"
      })
    }

    // Due date
    const dueDateElement = document.getElementById('due-date');
    if (dueDateElement) {
      // Determine if the dueDateElement has a value
      const hasDueDate = dueDateElement.value !== '';

      // Initialize flatpickr with conditional options
      flatpickr(dueDateElement, {
        defaultDate: hasDueDate ? dueDateElement.value : null,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no due date, set the placeholder dynamically
          if (!hasDueDate) {
            instance.altInput.placeholder = 'Select Due Date';
          }
        }
      });
    }

    // Date engagement letter sent to client
    const targetDateEngagementLetterSentElement = document.getElementById('engagement-letter-sent-date');
    if (targetDateEngagementLetterSentElement) {
      const targetDefaultDateEngagementLetterSent = targetDateEngagementLetterSentElement.value;

      flatpickr(targetDateEngagementLetterSentElement, {
        defaultDate: targetDefaultDateEngagementLetterSent,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y"
      })    
    }    

    // Date engagement letter received from client
    const targetDateEngagementLetterReceivedElement = document.getElementById('engagement-letter-received-date');
    if (targetDateEngagementLetterReceivedElement) {
      const targetDefaultDateEngagementLetterReceived = targetDateEngagementLetterReceivedElement.value;

      flatpickr(targetDateEngagementLetterReceivedElement, {
        defaultDate: targetDefaultDateEngagementLetterReceived,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y"
      })    
    }      

    // Target date
    const targetDateElement = document.getElementById('target-date');
    if (targetDateElement) {
      const targetDefaultDate = targetDateElement.value ? targetDateElement.value : new Date();

      flatpickr(targetDateElement, {
        defaultDate: targetDefaultDate,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y"
      })    
    }

    // Date Provided
    const dateProvidedElement = document.getElementById('date-provided');
    if (dateProvidedElement) {
      const dateProvidedDefaultDate = dateProvidedElement.value ? dateProvidedElement.value : ''; 

      flatpickr(dateProvidedElement, {
        defaultDate: dateProvidedDefaultDate,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!dateProvidedDefaultDate) {
            instance.altInput.placeholder = 'Select Date';
          }
        }
      });   
    }

    // Date Client Requested To Proceed With Advice
    const dateProceedElement = document.getElementById('date-client-requested-to-proceed');
    if (dateProceedElement) {
      const dateProceedDefaultDate = dateProceedElement.value ? dateProceedElement.value : '';

      flatpickr(dateProceedElement, {
        defaultDate: dateProceedDefaultDate,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!dateProceedDefaultDate) {
            instance.altInput.placeholder = 'Select Date';
          }
        }
      });   
    }

    // Date Advice Document Expires
    const dateExpiresElement = document.getElementById('date-advice-document-expires');
    if (dateExpiresElement) {
      const dateExpiresDefaultDate = dateExpiresElement.value ? dateExpiresElement.value : '';

      flatpickr(dateExpiresElement, {
        defaultDate: dateExpiresDefaultDate,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!dateExpiresDefaultDate) {
            instance.altInput.placeholder = 'Select Date';
          }
        }
      });   
    }

    // Date Implementation Forms Submitted
    const dateFormsSubmittedElement = document.getElementById('date-forms-submitted');
    if (dateFormsSubmittedElement) {
      // Determine if the dateFormsSubmittedElement has a value
      const hasDateFormsSubmitted = dateFormsSubmittedElement.value !== '';

      flatpickr(dateFormsSubmittedElement, {
        defaultDate: hasDateFormsSubmitted ? dateFormsSubmittedElement.value : null,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!hasDateFormsSubmitted) {
            instance.altInput.placeholder = 'Select Date Forms Submitted';
          }
        }
      });
    }

    // Date Implementation Completed
    const dateImplementationCompletedElement = document.getElementById('date-implementation-completed');
    if (dateImplementationCompletedElement) {
      // Determine if the dateImplementationCompletedElement has a value
      const hasDateImplementationCompleted = dateImplementationCompletedElement.value !== '';
    
      flatpickr(dateImplementationCompletedElement, {
        defaultDate: hasDateImplementationCompleted ? dateImplementationCompletedElement.value : null,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!hasDateImplementationCompleted) {
            instance.altInput.placeholder = 'Select Date Implementation Completed';
          }
        }
      });
    } 

    // Date Advice Provided to Client
    const dateAdviceProvidedElement = document.getElementById('date-advice-provided');
    if (dateAdviceProvidedElement) {
      const dateAdviceProvidedDefaultDate = dateAdviceProvidedElement.value ? dateAdviceProvidedElement.value : '';

      flatpickr(dateAdviceProvidedElement, {
        defaultDate: dateAdviceProvidedDefaultDate,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!dateAdviceProvidedDefaultDate) {
            instance.altInput.placeholder = 'Select Date';
          }
        }
      });   
    }

    // Date FSCG Provided to Client
    const dateFscgProvidedElement = document.getElementById('date-fscg-provided');
    if (dateFscgProvidedElement) {
      const dateFscgProvidedDefaultDate = dateFscgProvidedElement.value ? dateFscgProvidedElement.value : '';

      flatpickr(dateFscgProvidedElement, {
        defaultDate: dateFscgProvidedDefaultDate,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!dateFscgProvidedDefaultDate) {
            instance.altInput.placeholder = 'Select Date';
          }
        }
      });   
    }

    // Date Advice Document Provided to Client
    const dateAdviceDocProvidedElement = document.getElementById('date-advice-doc-provided');
    if (dateAdviceDocProvidedElement) {
      const dateAdviceDocProvidedDefaultDate = dateAdviceDocProvidedElement.value ? dateAdviceDocProvidedElement.value : '';

      flatpickr(dateAdviceDocProvidedElement, {
        defaultDate: dateAdviceDocProvidedDefaultDate,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        allowInput: true, // Allow manual input
        onReady: function(selectedDates, dateStr, instance) {
          // If there is no date, set the placeholder dynamically
          if (!dateAdviceDocProvidedDefaultDate) {
            instance.altInput.placeholder = 'Select Date';
          }
        }
      });   
    }

  }
}

